.benefit-header {
	background: #666;
	color: white;
	padding-top: 1rem;
}

.benefit-header h1 {
	font-size: 1.4rem;
	color:white
}

.benefit-header img {
	max-width: 191px;
}

.benefit-subheader {
	background: #efefef;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.benefit-subheader img {
/*	min-width: 80%;
	max-width: 166px;
*/
	width: 80%;
	margin-bottom: 2rem;
}


.benefit-footer {
	background: #969696;
	padding: 2rem;
	color: white !important;
}

p {
	margin-bottom: 0
}

footer.benefit-footer a {
	color: #aaa;
}