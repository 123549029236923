.benefit-footer {
	background: #222;
	padding: 2rem;
	color: #777 !important;
}

p {
	margin-bottom: 0
}

footer.benefit-footer a {
	color: #aaa;
}
