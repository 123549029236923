
.benefit-card {
	text-align: left;	
	padding-bottom: 3rem;
	border-bottom: 1px solid #333;
	margin: 3rem 0;
}

.benefit-card:last-child{
	border-bottom: none;
}

.benefit-card h2{
	text-transform: uppercase;	
	font-size: 1.4rem;
	max-width: 300px;
	border-bottom: 3px solid #4F154E;
	padding-bottom: 0.8rem;
	margin-bottom: 1.2rem;
}

.title {
	text-transform: uppercase;
}

.carrier-info .col, .carrier-info .col-sm, .carrier-info .col-md{
	margin-bottom: 1rem;
}

.logo {
  max-width: 300px;
}
